.events {
    background-color: var(--light-turquoise);
    width: 100%;
    padding: 9.5% 0;
    overflow-x: hidden;

}

@media only screen and (max-width: 1440px) {
    .events {
        padding-left: 11%;
        padding-right: 11%;
    }
}

@media (max-width: 576px) {
    .events {
        padding: 140px 2px 30px 2px;
    }
}


.title {
    font-family: 'Bebas Neue Pro', sans-serif;
    font-size: 5.65rem;
    color: var(--text-color);
    margin-bottom: 0 !important;
    line-height: 6rem;
}


.subtitle {
    font-family: "Noto Sans JP", sans-serif;
    color: #fff;
    font-size: 1.5rem;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.5rem;
        line-height: 3.5rem;
    }

    .subtitle {
        font-size: 1rem;
        margin-left: 6px
    }
}


.moreLink {
    width: calc(100% + 10px);
    display: flex;
    justify-content: end;
    margin-top: 20px;
    margin-right: -10px;
}


.slide {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    margin-top: 40px;
}

@media (max-width: 576px) {
    .slide {
        margin-left: -80%;
        margin-right: -80%;
        width: calc(100% + 160%);
        margin-top: 25px;
    }
}


.NextArrow {
    width: 55px !important;
    height: 55px !important;
    background-image: url(../../../../public/img/slide_arrow.webp);
    background-size: contain;
    transform: rotate(-180deg);
    top: 29%;
    right: -6.2%;
    position: absolute;
    cursor: pointer;
}

.PrevArrow {
    width: 55px !important;
    height: 55px !important;
    background-image: url(../../../../public/img/slide_arrow.webp);
    background-size: contain;
    top: 29%;
    left: -6.2%;
    position: absolute;
    cursor: pointer;
}

@media (max-width: 576px) {
    .NextArrow {
        width: 55px !important;
        height: 55px !important;
        background-image: url(../../../../public/img/slide_arrow.webp);
        background-size: contain;
        transform: rotate(-180deg);
        top: 100%;
        right: 42%;
        position: absolute;
        cursor: pointer;
    }

    .PrevArrow {
        width: 55px !important;
        height: 55px !important;
        top: 100%;
        left: 42%;
        position: absolute;
        cursor: pointer;
    }

    .moreLink {
        width: 100%;
        display: flex;
        justify-content: end;
        margin-top: 20%;
        margin-right: -10px;
    }
}