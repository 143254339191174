.footer {
    background-color: #212121;
    padding: 30px 0 20px 0;
    margin-top: auto;
}

.brand {
    text-decoration: none;
}



.brand img {
    width: 230px;
}



.links {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

@media (max-width: 576px) {
    .links {
        margin: 20px 0px;
        padding: 0 10%;
        justify-content: space-between;
    }
}

@media (max-width: 400px) {
    .links {
        padding: 0 7%;
    }
}

.link {
    text-decoration: none;
    color: white;
    margin: 0 30px;
    font-family: "Noto Sans JP", sans-serif;
}

@media (max-width: 576px) {
    .link {
        margin: 20px 0;
        font-size: 0.95rem;
        width: 50%;
        text-align: center;
    }
}

@media (max-width: 375px) {
    .link {
        font-size: 0.9rem;
    }
}

.social {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

@media (max-width: 576px) {
    .social {
        margin-top: 30px;
        margin-bottom: 0px;
    }
}

.socialLink img {
    width: 40px;
}

@media (max-width: 576px) {
    .socialLink img {
        width: 30px;
    }
}

.hr {
    color: #fff;
    height: 2px;
    width: 98%;
    margin: 0 1%;
    opacity: 1;
}

@media (max-width: 576px) {
    .hr {
        width: 90%;
        margin: 0 5%;
    }
}

.copyright {
    color: white;
    margin-top: 10px;
    margin-bottom: 0px;
    text-align: center;
}

@media (max-width: 576px) {
    .copyright {
        font-size: 0.8rem;
    }
}

.mobileItem {
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    margin: 20px 20px;
}

.mobileItem p:first-child {
    font-family: 'Bebas Neue Pro', sans-serif;
    color: white;
    font-size: 1.7rem;
    line-height: 3rem;
    font-weight: 400;
    margin-bottom: 0 !important;
}

.mobileItem p:last-child {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--light-turquoise);
    font-size: 0.9rem;
    line-height: 2.6rem;
    font-weight: 400;
    margin-bottom: 0 !important;
    margin-left: 7px;
}