.members {
    margin-top: 120px;
    padding-top: 70px;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 10%;

}

@media (max-width: 576px) {
    .members {
        margin-top: 135px;
        padding-top: 50px;
        padding-left: 1.5%;
        padding-right: 1.5%;
    }
}

.title {
    color: var(--title-color);
    font-family: 'Bebas Neue Pro', sans-serif;
    text-align: center;
    font-size: 3.6rem;
    letter-spacing: 0.12rem;
    margin-bottom: 0px;
}

@media (max-width: 576px) {
    .title {
        text-align: start;
        letter-spacing: 1px;
        line-height: 3.6rem;
        margin-top: 5px;
    }
}

.subtitle {
    color: var(--light-turquoise);
    font-family: "Noto Sans JP", sans-serif;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
}

@media (max-width: 576px) {
    .subtitle {
        text-align: start;
        letter-spacing: 1px;
        letter-spacing: -0.5px;

    }
}


.memeberName {
    color: var(--light-turquoise);
    font-family: "Noto Sans JP", sans-serif;
    margin-bottom: 0;
    margin-top: 35px;
    font-size: 1.6rem;
    margin-left: -4px;
    letter-spacing: 1px;
    line-height: 2.4rem;
    font-weight: 400;
}

@media (max-width: 576px) {
    .memeberName {
        font-size: 1.4rem;
        margin-left: 0px;
        line-height: 2rem;
        margin-top: 0px;
        color: var(--title-color);
        margin-top: 30px;

    }

}

.memeberTitle {
    color: var(--title-color);
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
    letter-spacing: 3px;
    margin-left: -4px;
    font-weight: 400;
}

@media (max-width: 576px) {
    .memeberTitle {
        font-size: 0.8rem;
        margin-left: 0px;
        margin-top: 0px;
        letter-spacing: 1px;
        color: var(--light-turquoise);
        margin-bottom: 8px;
    }

}

.link {
    text-decoration: none;
    display: block;
    transition: all 0.5s ease-in;
}

.link:hover {
    transform: scale(1.05);
}



.loadMore {
    border: 2px solid var(--light-turquoise);
    background-color: white;
    color: #333;
    padding: 13px 60px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-decoration: none;
    text-align: center;
}

@media (max-width: 576px) {
    .loadMore {
        width: 230px;
        border-radius: 3px;
        padding: 15px 0;
    }
}

.sugetionSearch {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--title-color);
    text-align: start;
    margin-left: auto;
    margin-top: 10px;
    width: 270px;
}