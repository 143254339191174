.whatIsVmd {
    margin-top: 120px;
    margin-bottom: 10%;
}

@media (max-width: 576px) {
    .WhatIsVmd {
        margin-top: 135px;
    }
}

.pageIntro {
    background-image: url(../../../public/img/whatisvmd.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    max-width: 100%;
    height: 47.75vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 576px) {

    .pageIntro {
        width: 100vw;
        height: 90.5vw;
        background-image: url(../../../public/img/whatisSm.webp);

    }
}

.title {
    font-size: 6.5rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    line-height: 6.2rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.7rem;
        line-height: 4rem;
        margin-right: auto;
        margin-left: auto;
        font-weight: 300;
    }
}

.subtitle {
    font-size: 1.6rem;
    color: rgb(255, 255, 255);
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: 300;
}

.vmdText {
    font-size: 2rem;
}

@media (max-width: 576px) {
    .subtitle {
        font-size: 1.35rem;
        letter-spacing: 0.1rem;
        margin-right: auto;
        margin-left: auto;
    }

    .vmdText {
        font-size: 1.35rem;
    }
}

.firstDescriptionContainer {
    font-family: "Noto Sans JP", sans-serif;
    margin: 0 !important;
    letter-spacing: 0.18rem;
    line-height: 2.5rem;
    background-color: var(--light-turquoise);
    padding: 3% 0;
}

@media (max-width:576px) {
    .firstDescriptionContainer {
        padding: 2% 2%;
    }
}

.firstDescriptionTitle {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 10px;
}

@media (max-width:576px) {
    .firstDescriptionTitle {
        font-size: 1.5rem;
        margin-top: 14%;
        margin-bottom: 1.9rem;
    }
}

.firstDescription {
    font-size: 1rem;
    letter-spacing: -1px;
    font-weight: 500;
}

@media (max-width:576px) {
    .firstDescription {
        font-size: 1.1rem;
        line-height: 1.9rem;
        text-align: justify;
        font-family: "Noto Sans JP", sans-serif;
        margin-bottom: 23%;

    }
}


.secondDescriptionContainer {
    border: 3px solid #7fccba;
    width: 820px;
    margin: 5% auto;
    padding: 4% 2%;
}


@media (max-width: 576px) {
    .secondDescriptionContainer {
        border: 3px solid #7fccba;
        width: 90%;
        padding: 4% 2%;
    }
}

.secondDescriptiontitle {
    font-family: "Noto Sans JP", sans-serif;
    margin-top: 0%;
    margin-bottom: 7%;
    color: var(--light-turquoise);
    font-size: 1.6rem;
}

.secondDescription {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
    text-align: justify;
    font-weight: 500;
    letter-spacing: -1px;
    color: var(--title-color);
}

@media (max-width: 576px) {
    .secondDescription {
        padding: 1% 0%;
        font-size: 1rem;
        text-align: justify;
    }

    .secondDescriptiontitle {
        font-size: 1.6rem;
    }
}

.thirdDescriptionContainer {
    background-color: var(--light-turquoise);
    width: 820px;
    margin: 5% auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4% 2%;
}

.thirdDescTitle {
    font-family: "Noto Sans JP", sans-serif;
    color: white;
    font-size: 1.6rem;
    margin-bottom: 22px;
    text-align: center;
}

.thirdDescDetails {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
    text-align: justify;
    font-weight: 500;
    letter-spacing: -1px;
}

@media (max-width: 576px) {
    .thirdDescriptionContainer {
        width: 90%;
        padding: 6% 6%;
    }

    .thirdDescDetails {
        font-size: 1.1rem;
    }

}

.vmdContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.vmdTitle {
    font-size: 1.6rem;
    font-family: "Noto Sans JP", sans-serif;
    margin-bottom: 2rem;
    color: var(--light-turquoise);
}

@media (max-width: 576px) {
    .vmdContainer {
        width: 100%;
        padding: 6% 6%;

    }

    .vmdTitle {
        text-align: center;
    }

}

.chartImg {
    max-width: 100%;
    width: 550px;
    height: 380px;
}

@media (max-width: 576px) {
    .chartImg {
        max-width: 100%;
        width: 310px;
        height: 220px;
    }
}

.vmdListItemContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 7%;
    font-family: "Noto Sans JP", sans-serif;

}

@media (max-width:576) {
    .vmdListItemContainer {
        padding: 4% 6%;
    }
}

.vmdListTitle {
    font-size: 1.6rem;
    font-family: "Noto Sans JP", sans-serif;
    color: var(--light-turquoise);
}

@media (max-width: 576px) {
    .vmdListTitle {
        max-width: 100%;
        padding: 6% 6%;
        text-align: center;

    }
}

.listItems {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 12%;
    margin-top: 1rem;
    font-family: "Noto Sans JP", sans-serif;
    color: var(--header-bg-color);
}

@media (max-width: 576px) {
    .listItems {
        text-align: justify;
        align-items: center;
        display: flex;
        margin-left: 4%;
    }
}


.border {
    border-bottom: 3px solid white;
    width: 812px;
    max-width: 100%;
    margin: 0 auto;
}

@media (max-width: 576px) {
    .border {
        border-bottom: 3px solid white;
        width: 340px;
        max-width: 100%;
        margin: 0 auto;
    }


}



.product {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.productTitle {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.4rem;
    color: #000;
    font-weight: 500;
    margin-top: 10%;

}

@media (max-width: 576px) {
    .productTitle {
        font-weight: 400;
        margin-top: 6%;
    }

}

.productDescription {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
    color: black;
    font-weight: 500;
    text-align: justify;
    margin-top: 6%;
}

@media (max-width: 576px) {
    .productDescription {
        /* font-weight: 400; */
        font-size: 1rem;
        font-weight: 500;
    }
}


.vmdContainer {
    padding: 20px;
    font-family: "Noto Sans JP", sans-serif;
    margin-top: 5%;
}

.mainTitle {
    font-size: 1.5rem;
    font-family: "Noto Sans JP", sans-serif;
    color: white;
    font-weight: 300;
    text-align: center;
    margin-bottom: 5%;
}

@media (max-width: 576px) {
    .mainTitle {
        text-align: center;
        font-size: 1.9rem;
        font-weight: 400;
    }
}

.mainSubtitle {
    font-size: 1.1rem;
    color: var(--title-color);
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 500;
}


.contentSection {
    margin-top: 30px;
    background-color: var(--turquoise);
    display: flex;
    gap: 60px
}

@media (max-width: 576px) {
    .contentSection {
        gap: 40px;
        flex-direction: column;
        align-items: center;
    }

}


.vmdContainerLast {
    background-color: var(--turquoise);
    padding: 20px;
    font-family: "Noto Sans JP", sans-serif;
    margin-top: 10%;
}

.containerSize {
    max-width: 820px;
    width: 100%;
    margin: 0 auto;
}

.textSection {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    font-family: "Noto Sans JP", sans-serif;
    text-align: justify;
    margin-right: 40px;
}

.styled-list {
    font-weight: 500;
}

@media (max-width: 576px) {
    .textSection {
        font-size: 1rem;
        margin-right: 0px;
    }
}

.imgFluid {
    width: 262px;
    height: auto;
}

@media (max-width: 576px) {
    .imgFluid {
        width: 262px;
        height: 268px;
    }
}

.etc {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 500;
    margin-top: 50px;
}

@media (max-width: 576px) {
    .etc {
        font-weight: 700;
        margin-top: 32px;
    }
}