.sent {
  margin-top: 15%;
  /* margin-bottom: 7%; */
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif;
  color: var(--title-color);
}

.thanks {
  margin-top: 18px;
  /* margin-bottom: 7%; */
  display: flex;
  text-align: center;
  justify-content: center;
  color: var(--title-color);
  font-size: 0.90rem;
  font-weight: 700;
  font-family: "Noto Sans JP", sans-serif;
  /* letter-spacing: -2px; */
}

.receive {
  margin-top: 18px;
  /* margin-bottom: 7%; */
  display: flex;
  text-align: center;
  justify-content: center;
  color: var(--title-color);
  font-size: 0.90rem;
  font-weight: 700;
  font-family: "Noto Sans JP", sans-serif;
}

.email {
  margin-top: 25px;
  margin-bottom: 7%;
  display: flex;
  text-align: center;
  justify-content: center;
  color: var(--title-color);
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif;
}

@media (max-width: 576px) {
  .sent {
    margin-top: 50%;
  }

  .thanks {
    font-size: 0.9rem;
  }

  .email {
    margin-bottom: 20%;
  }
}