.membership {
    margin-top: 120px;
    margin-bottom: 7%;
}

@media (max-width: 576px) {
    .membership {
        margin-top: 135px;
    }

    .membership .container {
        padding-right: calc(calc(var(--bs-gutter-x)* .5) + 15px) !important;
        padding-left: calc(calc(var(--bs-gutter-x)* .5) + 15px) !important;
    }
}


.pageIntro {
    background-image: url(../../../public/img/member_reg.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    max-width: 100%;
    height: 27vw;
    display: flex;
    justify-content: center;
    align-items: self-start;
    flex-direction: column;
}


.title {
    font-size: 6.5rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    line-height: 6.2rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.7rem;
        line-height: 4rem;
        margin-right: auto;
        margin-left: auto;
        font-weight: 300;
        display: block;
    }
}

.subtitle {
    font-size: 1.6rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: 300;
}

@media (max-width: 576px) {
    .subtitle {
        font-size: 1.35rem;
        letter-spacing: 0.1rem;
        font-family: 'Bebas Neue Pro', sans-serif;
        font-weight: 300;
        margin-right: auto;
        margin-left: auto;
        display: block;
    }
}


@media (max-width: 576px) {

    .pageIntro {
        width: 100vw;
        height: 90.5vw;
        background-image: url(../../../public/img/member_reg-sm.webp);

    }
}

.introTitle {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 2rem;
    margin-top: 7%;
    margin-left: -1.6%;
    text-align: center;
    font-weight: 500;
}

@media (max-width: 576px) {
    .introTitle {
        text-align: start;
        font-size: 1.2rem;
        margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 20px;
    }
}

.introtext {
    text-align: center;
    font-family: "Noto Sans JP", sans-serif;
    color: var(--dark-gray);
    font-size: 1.7rem;
}

@media (max-width: 576px) {

    .introtext {
        font-size: 1.3rem;
    }
}

@media (max-width: 410px) {
    .introtext {
        font-size: 1.18rem;
    }
}

.item {
    border-bottom: 2px solid var(--light-turquoise);
    display: flex;
    align-items: baseline;
    margin: 40px 30px;
}

@media (max-width: 576px) {
    .item {
        flex-direction: column;
        margin: 60px 0px;

    }
}

.item label {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--dark-gray);
    white-space: nowrap;
    font-size: 1.1rem;
    margin-right: 30px;
}

@media (max-width: 576px) {
    .item label {
        margin-bottom: 0px;
        font-size: 1rem;
    }
}

.item span {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--light-turquoise);
    font-size: 0.8rem;
    margin-right: 30px;
    margin-left: -10px;
    white-space: nowrap;
}

@media (max-width: 576px) {
    .item span {
        /* display: none; */
    }
}

.item input {
    width: -webkit-fill-available;
    border: none;
    outline: none;
    font-size: 1.4rem;
    color: var(--title-color);
}

.item input:focus {
    outline: none;
    box-shadow: none !important;

}

@media (max-width: 576px) {
    .item input {
        font-size: 1.3rem;
    }
}

.item input:focus {
    border: none;
    outline: none;
}



.sectionName {
    color: var(--light-turquoise);
    margin-bottom: -40px;
    margin-left: 30px;
    margin-top: 100px;
    font-size: 1.1rem;
}

@media (max-width: 576px) {
    .sectionName {
        margin-left: 0px;
        margin-top: 70px;
        margin-bottom: -60px;
    }
}

.tp {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--dark-gray) !important;
    white-space: nowrap;
    font-size: 1.1rem !important;
    margin-right: 30px;
    margin-left: -20px;
}

.upload {
    background-color: #E6E6E6 !important;
    padding: 5px 30px !important;
    font-size: 0.9rem;
    margin-bottom: 0 !important;
    border: 1px solid;
}

@media (max-width: 576px) {
    .upload {
        padding: 0px 20px !important;
    }
}

.upload:hover {
    border: 1px solid;
}

.item textarea {
    width: 100%;
    margin-top: 20px;
    height: 280px;
    font-family: "Noto Sans JP", sans-serif;
    border: none;
    outline: none;
    font-size: 1.2rem;
    color: var(--title-color);
    line-height: 2.8rem;
}

@media (max-width: 576px) {

    .item textarea {
        font-size: 1rem;
        line-height: 2rem;
        margin-top: -5px;
    }
}

.pinfo {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--dark-gray);
    margin-left: 30px;
    margin-top: 20px;
}

@media (max-width: 576px) {
    .pinfo {
        margin-left: 0;
        font-size: 0.9rem;
    }
}

.terms {
    font-family: "Noto Sans JP", sans-serif;
    margin-left: 10px;
    color: var(--title-color);
    font-size: 1.1rem;

}

.checkbox {
    width: 25px;
    height: 25px;
    margin-left: 30px;
}

@media (max-width: 576px) {
    .terms {
        margin-left: 0;
        font-size: 0.9rem;
        margin-left: 10px;
    }

    .checkbox {
        width: 20px !important;
        height: 20px !important;
        margin-left: 0px;
    }
}

.terms a {
    text-decoration: none;
    color: var(--turquoise);
}

.submitButton {
    border: 2px solid var(--light-turquoise);
    background-color: white;
    color: #333;
    padding: 13px 40px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-decoration: none;
    text-align: center;
    width: 230px;
}

@media (max-width: 576px) {
    .submitButton {
        padding: 10px;
        width: 300px;
        margin-bottom: 50px;
    }
}

.submitButton:hover {
    background-color: var(--turquoise) !important;
    border: 2px solid var(--turquoise) !important;
}

.submitButton:disabled {
    color: var(--text-color) !important;
    border: 2px solid var(--turquoise);
    background-color: #33333310 !important;
}

.nomineeInform {
    color: #E60013;
    font-family: "Noto Sans JP", sans-serif;
    margin-bottom: 60px;
    margin-left: 30px;
    margin-top: 20px;
}

@media (max-width: 576px) {
    .nomineeInform {
        margin-bottom: 60px;
        margin-left: 0px;
        margin-top: 20px;
    }
}

.formError {
    color: #E60013;
    font-family: "Noto Sans JP", sans-serif;
    margin-left: 30px;
    margin-top: -15px;
}

@media (max-width: 576px) {
    .formError {
        margin-left: 0px;
        margin-top: -40px;
    }
}

.itemErrorBorder {
    border-color: #E60013 !important;
}

.invalidCheckbox {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    -webkit-box-shadow: 0px 0px 2px 2px rgba(255, 0, 0, 1);
    -moz-box-shadow: 0px 0px 2px 2px rgba(255, 0, 0, 1);
    box-shadow: 0px 0px 2px 2px rgba(255, 0, 0, 1);
}

.subHead {
    margin-top: 90px;
    font-family: "Noto Sans JP", sans-serif;
    color: var(--turquoise);
    font-size: 1.1rem;
    margin-left: 30px;
}