.contactus {
    margin-top: 120px;
    margin-bottom: 7%;
}

form {
    width: 100%;
    /* max-width: 788px; */
    /* 850px */
    max-width: 850px;
}

.pageIntro {
    background-image: url(../../../public/img/contact.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    max-width: 100%;
    height: 27vw;
    display: flex;
    justify-content: center; 
    align-items: self-start;
    flex-direction: column;
    
}

.introTitle {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--dark-gray);
    font-size: 1rem;
    margin-top: 5%;
    text-align: center;
    font-weight: 500;
    line-height: 2.2rem;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 40px;
    padding-bottom: 4px;
  }   

.title {
    font-family: 'Bebas Neue Pro', sans-serif;
    font-size: 6.5rem;
    color: #ffffff;
    margin-bottom: 0 !important;
    line-height: 6rem;
}

.subtitle {
    font-family: "Noto Sans JP", sans-serif;
    color: #ffffff;
    font-size: 2rem;
}

.customInput::placeholder {
    color: var(--title-color);
    opacity: 1; 
  }

  .customInput {
    outline: none !important;
    box-shadow: none !important;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    padding-left: 60px;
    /* color: #9EDBD6 !important; */
    
  }

.textinput {
    border-radius: 0;
    border-bottom: 3px solid #9EDBD6;
    font-size: 0.8rem;
    font-family: "Noto Sans JP", sans-serif;
    margin-left: 102px;
    margin-right: 130px; 
    margin-bottom: 42px;
    
}

.agrement {
    border-radius: 0;
    border-bottom: 3px solid #9EDBD6;
    font-size: 0.8rem;
    font-family: "Noto Sans JP", sans-serif;
    margin-left: 102px;
    margin-right: 130px; 
    padding-bottom: 6px;
    margin-bottom: 42px;
}

.textarea {
    border: none;
    outline: none;
    border-radius: 0;
    border-bottom: 3px solid #9EDBD6;
    color: var(--title-color);

    height: 100px; 
    resize: none; 
    overflow: hidden;
}

.customTextarea::placeholder {
    color:var(--title-color);    
}

.label {
    color:var(--dark-gray);    
    letter-spacing: -1px;
    margin-left: -12px;
    font-family: "Noto Sans JP", sans-serif;
}

.textinput:focus,
.textarea:focus {
    box-shadow: none
}

.send {
    width: 270px;
    background-color: var( --turquoise);
    border-color: #9EDBD6;
    color: white;
    font-size: 1rem;
    padding: 12px;
}

.cancel {
    width: 270px;
    background-color:#F58C00;
    border-color: #F58C00;
    color: var(--text-color);
    font-size: 1rem;
    color: white;
    padding: 12px;
}

.CustomBtnContainer {
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 107px;
    
}

.customTextarea {
    outline: 'none';
    box-shadow: none;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    max-width: 760px;
    padding: 0px;
    box-shadow: none; 
    
}

.send:hover {
    background-color: #b0b0b0;
    border-color: #9EDBD6;
}

.cancel:hover {
    background-color: #b0b0b0;
    border-color: #9EDBD6;
}

.trems {
    font-size: 1rem;
    color: #000000;
    margin-left: -12px;
}

@media (max-width: 576px) {

    .pageIntro {
        width: 100vw;
        height: 90.5vw;
        background-image: url(../../../public/img/contactmb.webp);
  
    }

    .title {
        font-size: 3.5rem;
        line-height: 3.5rem;
    }

    .subtitle {
        font-size: 1.3rem;
        margin-left: 6px;
        font-weight: 500;

    }

    .textinput, .agrement {
        margin-left: 16px;
        margin-right: 26px;
    }

    .textinput,
    .textarea {
        border-bottom: 2px solid #9EDBD6;

    }

    .introTitle {
        text-align: justify;
        font-size: 1rem;
        margin-left: 16px;
        margin-right: 26px;
        padding-bottom: 0px;
    }

    .customInput{
        text-align: left !important;
        margin-left: -12px;
        font-size: 1.1rem;
        padding-left: 0px !important;
    }

    .CustomBtnContainer {
        display: flex; 
        flex-direction: column; 
        margin-top: 60px;
        margin-bottom: 44px;
        
    }

    .send, .cancel {
        width: 290px;
        padding: 12px;

    }
    .cancel {
        margin-top: -14px;
    }

    .textarea {
        font-size: .88rem;
        overflow: hidden;

    }

    .customTextarea {
        height: 120px;
        width: 100%;
    }

    .trems {
        font-size: .88rem;
    }

    .agrement {
        border-bottom: 2px solid #9EDBD6;
    }
}