.linkMore {
    display: flex;
    text-decoration: none;
    align-items: center;
}

.linkMore p {
    font-family: "Lato", sans-serif;
    color: var(--text-color);
    font-size: 1.5rem;
    letter-spacing: 3px;
    margin-bottom: 0 !important;
}

@media (max-width: 576px) {
    .linkMore p {
        font-size: 1.2rem;
        letter-spacing: 0px;
    }
}

.linkMore img {
    width: 40px;
    height: auto;
}

@media (max-width: 576px) {
    .linkMore img {
        width: 30px;
        height: auto;
        margin-left: 5px;
    }
}

.whiteTxt p {
    color: white !important;
}