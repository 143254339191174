.licensure {
    margin-top: 120px;
    margin-bottom: 10%;
}

@media (max-width: 576px) {
    .licensure {
        margin-top: 135px;
    }
}

.pageIntro {
    background-image: url(../../../public/img/licensure.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    max-width: 100%;
    height: 27vw;
    display: flex;
    justify-content: center;
    align-items: self-start;
    flex-direction: column;
}

@media (max-width: 576px) {

    .pageIntro {
        width: 100vw;
        height: 90.5vw;
        padding: 0 2%;
        background-image: url(../../../public/img/licensure-sm.webp);
    }
}



.title {
    font-size: 6.5rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    line-height: 6.2rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.7rem;
        line-height: 4rem;
        margin-right: auto;
        margin-left: auto;
        font-weight: 300;
    }
}

.subtitle {
    font-size: 1.6rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: 300;
}

@media (max-width: 576px) {
    .subtitle {
        font-size: 1.35rem;
        letter-spacing: 0.1rem;
        font-family: 'Bebas Neue Pro', sans-serif;
        font-weight: 300;
        margin-right: auto;
        margin-left: auto;
    }
}

.detailsContainer {
    margin: 7% 0;
}

.introTitle {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 1.1rem;
    margin-bottom: 5%;
}

@media (max-width: 576px) {
    .detailsContainer {
        margin: 7% 2%;
    }

    .introTitle {
        text-align: start;
        font-size: 1rem;
    }
}



.questionAnswer {
    margin-top: 7%;
    width: 93%;
    margin-right: 7%;
}

@media (max-width: 576px) {
    .questionAnswer {
        margin-top: 7%;
        width: 100%;
        margin-right: 0%;
    }
}

.collapsItem {
    margin-bottom: 10px;
}

.question {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 2px solid var(--light-turquoise);
    cursor: pointer;
}

@media (max-width: 576px) {
    .question {
        padding: 10px 5px;
        border-width: 1px;
    }
}

.open {
    border: none;
}

.question img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 30px;
}

@media (max-width: 576px) {
    .question img {
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }
}

.collaspsIcon {
    height: 30px !important;
    width: 30px !important;
    object-fit: contain;
    margin-left: auto;
    transition: all 0.3s ease-in !important;
}

@media (max-width: 576px) {
    .collaspsIcon {
        height: 16px !important;
        width: 16px !important;
        margin-bottom: 10px;
    }
}

.open .collaspsIcon {
    transform: rotate(0deg) !important;
}

.close .collaspsIcon {
    transform: rotate(90deg) !important;
    opacity: 1;
}

.question p {
    color: var(--text-color);
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.1rem;
    margin-bottom: 18px;
}

@media (max-width: 576px) {
    .question p {
        font-size: 0.8rem;
        margin-bottom: 12px;
    }
}

.open .ques {
    color: #4BC5B9 !important;
}