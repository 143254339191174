.Contactus {
  padding: 4% 0;
  background-color: #fff;
  background-position: center;
  background-size: 20px;
  background-repeat: repeat;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 576px) {
  .Contactus {
    padding: 40px 0;
  }
}

.bgWhite {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 4% 0;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4% 0;
}

.content h1 {
  font-family: "Zen Maru Gothic", serif;
  font-weight: 500;
  color: var(--bright-yellow);
  font-size: 5rem;
  text-align: center;
  margin-bottom: 0 !important;
}

@media (max-width: 576px) {
  .content h1 {
    font-size: 2.9rem;
  }
}

.content p {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;
  font-size: 1.18rem;
  color: var(--dark-grayish);
  text-align: center;
  margin: 15px 0;
}

@media (max-width: 576px) {
  .content p {
    font-size: 0.78rem;
  }
}

.content a {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;
  font-size: 0.96rem;
  text-decoration: none;
  color: #fff;
  background-color: var(--forest-green);
  text-align: center;
  padding: 5px 15px;
  margin-top: 8px;
}

@media (max-width: 576px) {
  .content a {
    font-size: 1.18rem;
  }
}
