.item {
    margin: 0 15px;
    text-decoration: none;
    display: block;
}

.midItem {
    margin-left: 10px;
    margin-right: 10px;
}



.title {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 0;
    margin-left: 5px;
    color: var(--text-color);
    margin-top: 10px;
    letter-spacing: -2px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}


.date {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    margin-bottom: 5px;
    color: var(--text-color);
    font-size: 1.1rem;
    letter-spacing: 1.5px;
    margin-left: 5px;
}



.description {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
    margin-bottom: 0;
    padding-right: 10px;
    color: var(--text-color);
    margin-left: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 576px) {

    .item {
        margin: 0 20px;
        padding-bottom: 30px;
    }

    .textContent {
        opacity: 0;
        transition: opacity 0.5s ease-in;
    }

    .showTextContent {
        opacity: 1;
    }

    .titleDate {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin: 10px 0 15px 0;
    }

    .title {
        width: 67%;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-left: 0px;
        color: var(--text-color);
        margin-top: 0px;
        letter-spacing: -2px;
        margin-bottom: 0 !important;
    }

    .date {
        width: 27% !important;
        font-family: "Lato", sans-serif;
        font-weight: bold;
        margin-bottom: 5px;
        color: var(--text-color);
        font-size: 0.8rem;
        letter-spacing: 1.5px;
        margin-bottom: 0;
    }

    .description {
        font-size: 0.8rem;
    }


}