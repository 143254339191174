.about {
    width: 100%;
    padding: 8% 0;
}

@media only screen and (max-width: 1440px) {
    .about {
        padding-left: 11%;
        padding-right: 11%;
    }
}

@media (max-width: 576px) {
    .about {
        padding: 40px 2px 80px 2px;
    }
}

.title {
    font-family: 'Bebas Neue Pro', sans-serif;
    font-size: 5.65rem;
    color: var(--text-color);
    margin-bottom: 0 !important;
    line-height: 6rem;
}

.subtitle {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--light-turquoise);
    font-size: 1.5rem;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.5rem;
        line-height: 3.5rem;
    }

    .subtitle {
        font-size: 1rem;
        margin-left: 6px
    }
}

.info {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.3rem;
    color: var(--text-color);
    margin-bottom: 0 !important;
}

@media (max-width: 576px) {
    .info {
        font-size: 1.27rem;
    }
}

.linkMore {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 10px;
    margin-top: 15px;
}

.members {
    margin-top: 150px;
}

@media (max-width: 576px) {
    .members {
        margin-top: 80px;
    }
}

.memberTitle {
    font-family: 'Bebas Neue Pro', sans-serif;
    font-size: 3.4rem;
    color: var(--text-color);
    margin-bottom: 0 !important;
    font-weight: 500;
    text-align: center;
}

.hiroImgContainter {
    width: 280px;
    margin-right: 15px;
    position: relative;
}

@media (max-width: 576px) {
    .hiroImgContainter {
        width: 96%;
        margin-right: 0px;
        position: relative;
    }
}

.hiroShortName {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: min-content;
    width: min-content;
    text-wrap: nowrap;
    padding: 3px 7px;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 500;
}


.hiroDetails {
    width: 580px;
    margin-left: 15px;
}

.hiroGreetings {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--light-turquoise);
    font-size: 2.5rem;
}

@media (max-width: 576px) {
    .hiroGreetings {
        font-size: 1.6rem;
        text-align: center;
        margin-bottom: 0px !important;
        font-weight: 400;
    }
}

.hiroDescription {
    font-family: "Noto Sans JP", sans-serif;
    margin-top: 30px;
    font-size: 15px;
    text-align: justify;
    font-weight: 500;
    line-height: 1.8rem;
    margin-right: 15px;
}

@media (max-width: 576px) {
    .hiroDescription {
        font-size: 0.9rem;
        font-weight: 500;
        margin-top: 0;

    }
}

.nameLabel {
    color: var(--light-turquoise);
    font-size: 1.4rem;
    margin-bottom: 0;
    font-family: "Noto Sans JP", sans-serif;
}

@media (max-width: 576px) {
    .nameLabel {
        font-size: 1.6rem;
    }
}

.role {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
}

@media (max-width: 576px) {
    .role {
        font-size: 1.1rem;
    }
}

.button {
    border: 2px solid var(--light-turquoise);
    background-color: white;
    margin: 0 auto;
    color: #333;
    padding: 13px 0px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 200px;
    text-decoration: none;
    text-align: center;
}

@media (max-width: 576px) {
    .button {
        width: 230px;
        border-radius: 3px;
        padding: 15px 0;
    }
}

.button:hover {
    background-color: var(--light-turquoise);
    color: white;
}

.banner {
    position: relative;
}

.moreItemL,
.moreItemR {
    position: absolute;
    bottom: 10%;
}

.moreItemL {
    left: 10%;
}

.moreItemR {
    right: 10%;
}

@media (max-width: 576px) {
    .moreItemR {
        bottom: 6%;
        right: 10%;
    }
}

.imgbtnBanner {
    position: absolute;
    bottom: 10%;
    right: 0%;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 68%;
}

.imgbtnBanner img {
    width: 100%;
}