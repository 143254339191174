:root {
  --header-height: 92px;
  --links-height: 60px;
  --dark-gray: #a9a9a9;
  --gray-brown: #555350;
  --dark-grayish: #555350;
  --bright-yellow: #ffcd45;
  --steel-yellow: #ffcd45;
  --forest-green: #18943b;
  --steel-green: #18943b;
  --steel-gray: #66666650;
  --sky-blue: #00afec;
  --dark-charcoal: #333333;
  --light-gray: #f7f7f7;
  --light-grayish: #adacac;
  --softPeach: #ffecbd;
  --headerLinkColor: #555350;
  --link-blue: #0073cc;

  --cmn-ash-lite: #e2e2e2;
  --cmn-yollow-deep: #ffcd45;
  --cmn-yollow: #ffcd45;
  --cmn-text-color-dark: #2b2b2b;
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Noto+Sans+JP:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@300;400;500;700;900&display=swap');

@font-face {
  font-family: 'Bebas Neue Pro';
  src: url('../public/fonts/BebasNeuePro-Middle.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kozuka Gothic Pro';
  src: url('../public/fonts/小塚ゴシック Pr6N R.otf') format('opentype');

}

body {
  margin: 0 !important;
  max-width: 100%;
}

.content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

:root {
  --sidebar-bg-color: #1E1E1E;
  --header-bg-color: #1E1E1E;
  --title-color: #1E1E1E;
  --light-turquoise: #81D8D0;
  --turquoise: #80D4CC;
  --text-color: #2D2D2D;
  --text-label-color: #565656;
  --dark-gray: #666666;
}

.searchinput {
  width: 270px;
  margin-left: auto;
  height: 57px;
  border: 2px solid #000;
  border-radius: 10px;
  font-size: 1.2rem;
  padding-left: 15px;
}

.searchinput:focus {
  border-color: var(--light-turquoise);
  outline-color: #000;
  box-shadow: none;
}

.admin {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.ql-snow .ql-editor img {
  max-width: 100% !important;
}

.dnd-card {
  /* background-color: pink; */
  transition: 1s ease-in;
  border: 1px dashed transparent;
  border-radius: 0.375rem;
}

.dnd-card.dragging {
  /* background-color: orange; */
  border: 1px dashed red;
  border-radius: 0.375rem;
}

.dnd-card.dragging .card {
  opacity: .1;

}