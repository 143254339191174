.about {
    margin-top: 120px;
    margin-bottom: 10%;
}

@media (max-width: 576px) {
    .about {
        margin-top: 135px;
    }
}


.pageIntro {
    background-image: url(../../../public/img/about.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    max-width: 100%;
    height: 47.75vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 576px) {

    .pageIntro {
        width: 100vw;
        height: 90.5vw;
        background-image: url(../../../public/img/about-sm.webp);

    }
}

.title {
    font-size: 6.5rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    line-height: 6.2rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.7rem;
        line-height: 4rem;
        margin-right: auto;
        margin-left: auto;
        font-weight: 300;
    }
}

.subtitle {
    font-size: 1.6rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: 300;
}

@media (max-width: 576px) {
    .subtitle {
        font-size: 1.35rem;
        letter-spacing: 0.1rem;
        color: white;
        font-family: 'Bebas Neue Pro', sans-serif;
        font-weight: 300;
        margin-right: auto;
        margin-left: auto;
    }
}

.items {
    display: flex;
    gap: 60px;
    width: 820px;
    margin: 5% auto;
    justify-content: space-between;
    flex-wrap: wrap;

}

@media (max-width: 576px) {
    .items {
        margin-top: 0;
        width: 100%;
        gap: 0px;
    }
}

.item {
    width: 345px;
    padding: 60px 20px 10px 20px;
    cursor: pointer;
    transition: 0.5s;
}

@media (max-width: 576px) {
    .item {
        width: 100%;
        padding: 60px 28px 25px 28px;
    }
}

@media (max-width: 375px) {
    .item {
        width: 100%;
        padding: 60px 28px 60px 28px;
    }
}

.item:hover {
    transform: scale(1.05);
}

@media (max-width: 576px) {
    .item:hover {
        transform: scale(1);
    }
}

.itemContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.itemImg {
    width: 50px;
}

@media (max-width: 576px) {
    .itemImg {
        width: 90px;
    }
}

.itemImg img {
    width: 100%;
    object-fit: contain;

}

.itemTitle {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: var(--title-color);

}

.itemDescription {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
    line-height: 1.7rem;
    color: var(--text-color);
}

.aboutTitle {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--title-color);

}

.aboutText {
    font-family: "Lato", sans-serif;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: white;
}

.aboutContainer {
    display: inline-flex;

}

.qsnImg {
    width: 40px;
    height: 40px;
}

.arroIconClass {
    width: 22px;
    height: 22px;
    margin-left: 3px;
    margin-bottom: 3px;
}

.green {
    background-color: var(--light-turquoise);
}

.white {
    border: 3px solid var(--light-turquoise);
}

@media (max-width: 576px) {
    .white {
        border: none;
    }

    .aboutText {
        text-align: center;
    }

}

.activity {
    background-color: var(--light-turquoise);
    width: 820px;
    margin: 5% auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4% 4%;
}

.activityAbout {
    background-color: var(--light-turquoise);
    width: 820px;
    margin: 5% auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4% 4%;
}

@media (max-width: 576px) {
    .activity {
        width: 100%;
        padding: 4% 7%;

    }

    .activityAbout {
        width: 100%;
        padding: 8% 8%;
        margin-bottom: 50px;

    }
}

.activity .itemImg {
    width: 70px;
}

.activityAbout .itemImgabout {
    width: 50px;
}

@media (max-width: 576px) {
    .activity .itemImg {
        width: 110px;
    }

    .activityAbout .itemImgabout {
        width: 75px;
    }

    .qsnImg {
        width: 60px;
        height: 60px;
    }
}

.activity .itemTitle {
    margin-top: 0px !important;
}

.activityAbout .itemTitle {
    margin-top: 0px !important;
}

.collapsItems {
    width: 100%;
    margin-top: 5%;
}

.collapsItem {
    margin-bottom: 40px;
}

@media (max-width: 576px) {
    .collapsItem {
        margin-bottom: 20px;
    }
}

.ItemTitleIcon {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.ItemTitleIcon p {
    margin-bottom: 0;
    margin-right: 10px;
    color: white;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.25rem;
}

.ItemTitleIcon img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    transition: 0.3s;
}



.collapseDetails {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
    color: var(--text-color);
}

.rotate90 img {
    transform: rotate(90deg);
}


.history {
    border: 3px solid var(--light-turquoise);
    width: 820px;
    margin: 5% auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4% 4%;
}

@media (max-width: 576px) {
    .history {
        width: 100%;
        border: none;
        padding: 4% 7%;
    }
}

.history_text {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
    line-height: 1.7rem;
    text-align: justify;
    color: var(--text-color);
}

.moreDeatails {
    width: 820px;
    margin: 0% auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0% 6%;
}

@media (max-width: 576px) {
    .moreDeatails {
        width: 100%;
        padding: 4% 15%;
    }
}

.section {
    margin: 7% 0;
    width: 100%;
}

@media (max-width: 576px) {
    .section {
        margin: 6% 0;
        width: 100%;
    }
}

.infoTitle {
    display: flex;
    align-items: baseline;
    width: 100%;
}

@media (max-width: 576px) {
    .infoTitle {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.infoTitle h4 {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    margin-right: 10px;
    font-size: 1.7rem;
}

@media (max-width: 576px) {
    .infoTitle h4 {
        margin-right: 0px;
        font-weight: 400;
        margin-bottom: 0px;
    }
}

.infoTitle p {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--light-turquoise);
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
}

@media (max-width: 576px) {
    .infoTitle p {
        font-weight: bold;
        font-size: 1.4rem;
    }
}

.infoDetails {
    display: flex;
    flex-direction: column;
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    white-space: nowrap;
}

.infoDetails a {
    font-family: "Noto Sans JP", sans-serif;
    text-decoration: none;
    color: var(--text-color);
    width: min-content;
    white-space: nowrap;
    margin: 5px 0;
    font-size: 1.1rem;
}

.anniversary {
    text-decoration: none;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
}

.anniversary img {
    width: 400px;
    height: auto;
}

@media (max-width: 576px) {
    .anniversary img {
        width: 100%;
        height: auto;
    }
}


.button {
    border: 2px solid var(--light-turquoise);
    background-color: white;
    margin: 0 auto;
    color: #333;
    padding: 13px 0px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 200px;
    text-decoration: none;
    text-align: center;
}

@media (max-width: 576px) {
    .button {
        width: 230px;
        border-radius: 3px;
        padding: 15px 0;
    }

    .organization {
        width: 350px;
    }
}

.button:hover {
    background-color: var(--light-turquoise);
    color: white;
}

.organizationContainer {
    margin-top: 4rem;
    margin-bottom: 14px;
}

.organization {
    border: 1px solid #aaa7a7;
}

.organizationImg {
    max-width: 100%;
}