/* start */

.contactus {
    margin-top: 120px;
    margin-bottom: 7%;
}

form {
    width: 100%;
    max-width: 850px;
}

.formIntroTitle {
    text-align: center;
    font-size: 1.3rem;
    margin-top: 10px;
    margin-bottom: 50px;
    color: var(--dark-gray);
}

.purchase {
    font-family: 'Bebas Neue Pro', sans-serif;
    text-align: center;
    font-size: 4rem;
    margin-bottom: 2px;
    font-weight: 500;

}

.purchaseSubtitle {
    text-align: center;
    color: #9EDBD6;
    font-size: 1.4rem;
}

.title {
    font-family: 'Bebas Neue Pro', sans-serif;
    font-size: 5rem;
    color: #ffffff;
    margin-bottom: 0 !important;
    line-height: 6rem;
}

.subtitle {
    font-family: "Noto Sans JP", sans-serif;
    color: #ffffff;
    font-size: 1.8rem;
}

.customInput {
    outline: none !important;
    box-shadow: none !important;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    /* padding-left: 60px; */

    width: 100%;
    min-width: 200px;
    padding: 0.375rem 0.75rem;


}

.textinput {
    border-radius: 0;
    border-bottom: 3px solid #9EDBD6;
    font-size: 0.8rem;
    font-family: "Noto Sans JP", sans-serif;
    /* margin-left: 60px;
    margin-right: 80px;  */
    margin-bottom: 42px;

}



.label {
    color: var(--dark-gray);
    letter-spacing: -1px;
    margin-left: -12px;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
}

.textinput:focus,
.textarea:focus {
    box-shadow: none
}

.CustomBtnContainer {
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 107px;

}

.send {
    border: 2px solid var(--light-turquoise) !important;
    background-color: white !important;
    color: #333 !important;
    padding: 13px 0px;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: all 0.3s ease, color 0.3s ease !important;
    width: 240px;
    text-decoration: none;
    text-align: center;
}

.send:hover {
    border: 2px solid var(--light-turquoise);
    background-color: var(--light-turquoise) !important;
    color: white;
}

.errorMsg {
    color: red;
    font-size: 0.875rem;
    margin-left: 12px;
}

.spaceRemover {
    margin-left: -1.85%;
}

.customInput::placeholder {
    color: var(--dark-gray);
    opacity: 0.5;
}

.productDecoration {
    text-align: center;
    font-weight: 800;
    color: var(--dark-gray);
    font-size: 1.2rem;
}



@media (max-width: 576px) {
    .title {
        font-size: 3.5rem;
        line-height: 3.5rem;
    }

    .subtitle {
        font-size: 1rem;
        margin-left: 6px;
    }

    .textinput,
    .agrement {
        margin-left: 16px;
        margin-right: 26px;
    }

    .textinput,
    .textarea {
        border-bottom: 2px solid #9EDBD6;
    }

    .introTitle {
        text-align: justify;
        font-size: 1rem;
        margin-left: 16px;
        margin-right: 26px;
        padding-bottom: 0px;
    }

    .customInput {
        text-align: left !important;
        margin-left: -12px;
        font-size: 0.88rem;
        padding-left: 0px !important;
    }

    .CustomBtnContainer {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        margin-bottom: 44px;

    }

    .formIntroTitle {
        font-size: 1.1rem;
        text-align: justify;
    }

    .purchase {
        font-size: 3.5rem;
    }

    .purchaseSubtitle {
        font-size: 1.1rem;
    }

    .errorMsg {
        margin-left: -12px;
    }

}