.licensure {
    margin-top: 120px;
    margin-bottom: 10%;
}

@media (max-width: 576px) {
    .licensure {
        margin-top: 135px;
    }


    .licensure .container {
        padding-right: calc(calc(var(--bs-gutter-x)* .5) + 5px) !important;
        padding-left: calc(calc(var(--bs-gutter-x)* .5) + 5px) !important;
    }
}


.pageIntro {
    background-image: url(../../../public/img/licensure.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    max-width: 100%;
    height: 27vw;
    display: flex;
    justify-content: center;
    align-items: self-start;
    flex-direction: column;
}

@media (max-width: 576px) {

    .pageIntro {
        width: 100vw;
        height: 90.5vw;
        background-image: url(../../../public/img/licensure-sm.webp);

    }
}

.title {
    font-size: 6.5rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    line-height: 6.2rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.7rem;
        line-height: 4rem;
        margin-right: auto;
        margin-left: auto;
        font-weight: 300;
    }
}

.subtitle {
    font-size: 1.6rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: 300;
}

@media (max-width: 576px) {
    .subtitle {
        font-size: 1.35rem;
        letter-spacing: 0.1rem;
        font-family: 'Bebas Neue Pro', sans-serif;
        font-weight: 300;
        margin-right: auto;
        margin-left: auto;
    }
}

.introtext {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 1.12rem;
    margin-right: 14%;
    line-height: 2rem;
    text-align: justify;
}

@media (max-width: 576px) {
    .introtext {
        margin-right: 5%;
        margin-left: 5%;
        font-size: 1rem;
        line-height: 1.7rem;
        margin-bottom: 0px;
    }
}

.introtext span {
    margin-left: -1%;
}

.crImg {
    width: 85%;
    margin-right: 15%;
    display: flex;
    gap: 6%;
    margin-top: 5%;
}


.crImg img {
    width: 45%;
}

@media (max-width: 576px) {
    .crImg {
        width: 74%;
        margin-right: 13%;
        margin-left: 13%;
        display: flex;
        flex-direction: column;
        gap: 6%;
        margin-top: -5px;
    }

    .crImg img {
        width: 100%;
        margin-top: 50px;
    }
}

.support {
    width: 100%;
    background-color: var(--light-turquoise);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 7% 6%;
    margin: 7% 0;
}

@media (max-width: 576px) {
    .support {
        margin-top: 60px;
    }
}

.itemTitle {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 0px;
    font-size: 1.8rem;
    color: var(--title-color);
}

.itemTitle_jp {
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 2.2rem;
    color: #388780;
}



.description {
    margin-top: 5%;
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    line-height: 2.2rem;
    font-weight: 400;
    font-size: 1.1rem;
}

@media (max-width: 576px) {
    .itemTitle {
        font-size: 1.6rem;
    }

    .itemTitle_jp {
        font-size: 2rem;
    }

    .description {
        margin-top: 3%;
        font-size: 1rem;
        line-height: 1.8rem;
    }
}

.supportsvg {
    width: 80%;
    margin-top: 6%;
    margin-bottom: 2%;
}

@media (max-width: 576px) {
    .itemTitle {
        font-size: 1.6rem;
    }

    .itemTitle_jp {
        font-size: 2rem;
    }

    .description {
        margin-top: 3%;
        font-size: 1.1rem;
        line-height: 1.8rem;
        margin-left: 3%;
        margin-left: 3%;
    }

    .supportsvg {
        width: 100%;
    }
}


.addressBook {
    width: 100%;
    background-color: var(--light-turquoise);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    margin: 7% 0;
}

@media (max-width: 576px) {
    .addressBook {
        margin: 60px 5%;
        width: 90%;
    }
}

.addressTable {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    font-size: 1.1rem;
    font-family: "Noto Sans JP", sans-serif;
    text-align: left;
    background-color: white;
    border-spacing: 0;
}

@media (max-width: 576px) {
    .addressTable {
        font-size: 1rem;
    }
}

.addressTable th,
.addressTable td {
    padding: 10px 0;
    border-color: transparent;
    border-width: 0;
}

@media (max-width: 576px) {

    .addressTable th,
    .addressTable td {
        padding: 10px 0;
    }
}

.addressTable th {
    background-color: #DFF2EF;
    font-weight: bold;
    text-align: left;
}

@media (max-width: 576px) {
    .addressTable th {
        background-color: white;
        font-weight: 500;
        text-align: left;
    }
}

.addressTable tr:nth-child(odd) {
    background-color: #FFFFFF;
}

.addressTable tr:nth-child(even) {
    background-color: #DFF2EF;
}

@media (max-width: 576px) {
    .addressTable tr:nth-child(even) {
        background-color: #FFFFFF;
    }

    .addressTable tr:nth-child(odd) {
        background-color: #DFF2EF;
    }
}


.addressTable td {
    color: #333;
}

.addressTable th:first-child,
.addressTable td:first-child {
    width: 15%;
    padding-left: 5%;
    border-right: 2px solid #388780;
}

@media (max-width: 576px) {

    .addressTable th:first-child,
    .addressTable td:first-child {
        width: 25%;
    }
}

.addressTable th:nth-child(2),
.addressTable td:nth-child(2) {
    width: 17%;
    padding-left: 4%;
}

@media (max-width: 576px) {

    .addressTable th:nth-child(2),
    .addressTable td:nth-child(2) {
        width: 30%;
        padding-left: 4%;
    }
}

.addressTable th:nth-child(3),
.addressTable td:nth-child(3) {
    width: 68%;
    padding-left: 2%;
}

@media (max-width: 576px) {

    .addressTable th:nth-child(3),
    .addressTable td:nth-child(3) {
        width: 45%;
        padding-left: 2%;
        font-size: 0.8rem;
        padding-right: 5%;
    }
}

.links {
    width: 100%;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

@media (max-width: 576px) {
    .links {
        flex-direction: column;
        gap: 20px;
    }
}



.button {
    border: 2px solid var(--light-turquoise);
    background-color: white;
    color: #333;
    padding: 13px 40px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-decoration: none;
    text-align: center;
}



.button:nth-child(1) {
    padding: 13px 30px;
}

.button:hover {
    background-color: var(--light-turquoise);
    color: white;
}

@media (max-width: 576px) {
    .button {
        width: 320px;
        border-radius: 3px;
        padding: 13px 0 !important;
        font-size: 0.95rem !important;
    }


}

.sidebar {
    display: flex;
    flex-direction: column;
}

@media (max-width: 576px) {
    .sidebar {
        display: none;
    }
}

.linkTitle {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.45rem;
    padding: 10px;
    padding-bottom: 15px;
    border-bottom: 2px solid var(--light-turquoise);
}

.sidebarLink {
    margin-left: 5%;
    width: 95%;
    text-decoration: none;
}

.sidebarLink {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.25rem;
    padding: 12px 10px;
    padding-bottom: 14px;
    color: var(--text-color);
    border-bottom: 1px solid var(--light-turquoise);
}

.headingContent {
    text-align: center;
}

.relatedtitle {
    text-transform: uppercase;
    font-family: 'Bebas Neue Pro', sans-serif;
    font-size: 4rem;
    font-weight: bold;
    color: var(--title-color);
}

.relatedsubtitle {
    font-size: 2.2rem;
    font-weight: 500;
    color: #388780;
    font-family: "Noto Sans JP", sans-serif;
    margin: -12px;
}

.ministries {
    margin-left: 14%;
    margin-top: 72px;
}

.organization {
    color: #388780;
    font-size: 2rem;
    font-weight: 400;

}

.information {
    font-size: 1.4rem;
    margin-top: 40px;
}

.development {
    margin-top: 40px;
}

.vocationalMain {
    color: #388780;
    font-size: 2.25rem;
}

.vocationalSub {
    font-size: 1.5rem;
    margin-top: 28px;
}

.forceLinkImage {
    width: 252px;
}

a {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 576px) {
    .relatedtitle {
        font-size: 3.2rem;
        font-weight: bold;

    }

    .relatedsubtitle {
        font-size: 1.75rem;
    }

    .ministries {
        text-align: center;
        margin-left: 0px;
    }

    .information {
        font-size: 1.2rem;
    }

    .vocationalMain {
        font-size: 2rem;
    }

    .vocationalSub {
        font-size: 1.4rem;
    }
}