.licensure {
    margin-top: 120px;
    margin-bottom: 10%;
}

@media (max-width: 576px) {
    .licensure {
        margin-top: 135px;
    }

    .licensure .container {
        padding-right: calc(calc(var(--bs-gutter-x)* .5) + 5px) !important;
        padding-left: calc(calc(var(--bs-gutter-x)* .5) + 5px) !important;
    }
}


.pageIntro {
    background-image: url(../../../public/img/history.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    max-width: 100%;
    height: 27vw;
    display: flex;
    justify-content: center;
    align-items: self-start;
    flex-direction: column;
}

@media (max-width: 576px) {

    .pageIntro {
        width: 100vw;
        height: 90.5vw;
        background-image: url(../../../public/img/history-sm.webp);

    }
}

.title {
    font-size: 6.5rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    line-height: 6.2rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.7rem;
        line-height: 3.5rem;
        margin-right: auto;
        margin-left: auto;
        font-weight: 300;
        margin-bottom: 10px;
    }
}

.subtitle {
    font-size: 1.6rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: 300;
}

@media (max-width: 576px) {
    .subtitle {
        font-size: 1.35rem;
        letter-spacing: 0.1rem;
        font-family: 'Bebas Neue Pro', sans-serif;
        font-weight: 300;
        margin-right: auto;
        margin-left: auto;
    }
}

.detailsContainer {
    margin: 7% 0;
}

.introTitle {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 1.6rem;
    margin-bottom: 5%;
}

@media (max-width: 576px) {
    .introTitle {
        text-align: center;
        font-size: 1.45rem;
    }
}


.introSubTitle {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 1.4rem;
}

@media (max-width: 576px) {
    .introSubTitle {
        font-size: 1rem;
        margin-bottom: 5px;
        margin-top: 5px;
    }
}


.certDetails {
    font-family: "Noto Sans JP", sans-serif;
    line-height: 2rem;
    font-size: 1.1rem;

}

@media (max-width: 576px) {
    .certDetails {
        line-height: 1.7rem;
        font-size: 0.91rem;

    }
}



.borderText {
    border: 3px solid var(--light-turquoise);
    font-family: "Noto Sans JP", sans-serif;
    line-height: 2rem;
    font-size: 1.1rem;
    padding: 20px 20px;
    margin: 4% 0;
}

@media (max-width: 576px) {
    .borderText {
        line-height: 1.7rem;
        font-size: 0.92rem;
    }
}

.leftBar {
    position: relative;
}

.leftBar::before {
    width: 8px;
    height: 16px;
    background-color: var(--light-turquoise);
    position: absolute;
    top: 7px;
    left: 4px;
    content: " ";
}

@media (max-width: 576px) {
    .leftBar::before {
        width: 11px;
        height: 16px;
        top: 5px;
        left: 2px;
    }
}

.redText {
    color: #FF0000 !important;
}

.arrowItem {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--light-turquoise);
    position: relative;
    font-size: 1.2rem;
    margin-bottom: 20px;
    opacity: 0.8;
    text-decoration: none;
}





.sidebar {
    display: flex;
    flex-direction: column;
}

@media (max-width: 576px) {
    .sidebar {
        display: none;
    }
}

.linkTitle {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.45rem;
    padding: 10px;
    padding-bottom: 15px;
    border-bottom: 2px solid var(--light-turquoise);
}

.sidebarLink {
    margin-left: 5%;
    width: 92%;
    text-decoration: none;
}

.sidebarLink {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.25rem;
    padding: 14px 10px;
    padding-bottom: 14px;
    color: var(--text-color);
    border-bottom: 1px solid var(--light-turquoise);
    margin-bottom: -2px;
}

.active {
    background-color: var(--light-turquoise);
    width: 100%;
    margin-left: 0;
    padding-left: calc(12px + 5%);
    color: white;
}