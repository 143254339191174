.publication {
    margin-top: 120px;
    margin-bottom: 6%;
}

@media (max-width: 576px) {
    .publication {
        margin-top: 135px;
    }

    .publication .container {
        padding-right: calc(calc(var(--bs-gutter-x)* .5) + 5px) !important;
        padding-left: calc(calc(var(--bs-gutter-x)* .5) + 5px) !important;
    }
}


.pageIntro {
    background-image: url(../../../public/img/publication.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    max-width: 100%;
    height: 27vw;
    display: flex;
    justify-content: center;
    align-items: self-start;
    flex-direction: column;
}


.title {
    font-size: 6.5rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    line-height: 6.2rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.7rem;
        line-height: 3.8rem;
        margin-right: auto;
        margin-left: auto;
        font-weight: 300;
        display: block;
    }
}

.subtitle {
    font-size: 1.6rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: 300;
}

@media (max-width: 576px) {
    .subtitle {
        font-size: 1.35rem;
        letter-spacing: 0.1rem;
        font-family: 'Bebas Neue Pro', sans-serif;
        font-weight: 300;
        margin-right: auto;
        margin-left: auto;
        display: block;
    }
}


@media (max-width: 576px) {

    .pageIntro {
        width: 100vw;
        height: 90.5vw;
        background-image: url(../../../public/img/publication-sm.webp);

    }
}

.introTitle {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 1.6rem;
    margin-top: 7%;
    margin-left: -1.6%;
}

@media (max-width: 576px) {
    .introTitle {
        text-align: center;
        font-size: 1.45rem;
    }
}

.introTitle2 {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 30px;

}

.introTitle3 {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.8rem;
    margin-top: 30px;
}

.boxText {
    background-color: var(--turquoise);
    padding: 30px 25px;
    margin-top: 30px;
    width: 80%;
    font-family: "Noto Sans JP", sans-serif;
    line-height: 1.75rem;
    color: var(--text-color);
    font-weight: 500;
}

.newText {
    font-family: "Noto Sans JP", sans-serif;
    color: red;
    width: 80%;
    font-weight: 500;
    margin-top: 30px;
    padding: 20px 10px;
    line-height: 1.75rem;
    font-size: 1rem;
}

.vmdImg {
    width: 80%;
    margin-top: 5%;
    margin-left: -10%;
}

@media (max-width: 576px) {
    .vmdImg {
        margin-left: 0%;
    }
}

.vmdtxt {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 0.73rem;
    color: var(--text-color);
    margin-top: 50px;
    font-weight: 500;
    margin-left: -10%;
    letter-spacing: -0.1px;
}

.bgdark {
    background-color: var(--sidebar-bg-color);
    margin-top: 10%;
    padding: 6% 0;
}

.bgdark .intro1 {
    font-family: "Noto Sans JP", sans-serif;
    color: white;
    font-size: 1.6rem;
    line-height: 2.8rem;
}

.bgdark .intro1 span {
    margin-left: -12px;
}

.bgdark .intro2 {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.1rem;
    color: var(--light-turquoise);
    margin-top: 5%;
    line-height: 1.7rem;
    text-align: justify;
}

.bgdark .intro2 span {
    margin-left: -12px;
}

.greenBG {
    background-color: var(--light-turquoise);
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
    color: var(--text-color);
    margin-top: 5%;
    line-height: 1.7rem;
    text-align: justify;
    padding: 30px 25px;
    line-height: 1.75rem;
    width: 90%;
    font-weight: 500;
}

.greenBG span {
    margin-left: -1.2%;
}

.magazine_vmd {
    width: 105%;
    margin-left: -5%;
}

.bgdark .vmdtxt {
    color: white !important;
    margin-left: 2%;
}



.linkButton {
    display: block;
    border: 2px solid var(--light-turquoise);
    background-color: white;
    margin: 0 auto;
    color: #333;
    padding: 13px 0px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 200px;
    text-decoration: none;
    text-align: center;
}

@media (max-width: 576px) {
    .linkButton {
        width: 230px;
        border-radius: 3px;
        padding: 15px 0;
    }
}

.linkButton:hover {
    background-color: var(--light-turquoise);
    color: white;
}

.mobileVmdG {
    background-color: #E2E2E2;
    display: flex;
    justify-content: center;
    padding: 0px 0 20px 0;
    width: 96%;
    margin: 0 2%;
    margin-bottom: 25px;

}

.mobileVmdG img {
    width: 40%;
}

.mbileIntro {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 1.25rem;
    margin-left: -4%;
}



.mbileIntroDate {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 1.25rem;
    margin-top: 1.7rem;
}

.mobileIntro {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    text-align: justify;
    font-size: 0.9rem;
}

.mobileGreen {
    background-color: var(--turquoise);
    width: 85%;
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    padding: 18px;
    margin-top: 20px;
    font-size: 0.9rem;
}

.newTextMobile {
    width: 85%;
    font-family: "Noto Sans JP", sans-serif;
    padding: 18px;
    margin-top: 20px;
    font-size: 0.9rem;
    color: red;

}

.mobileVmdTxt {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 2.99vw;
    letter-spacing: -0.3px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.mobileDarkBg {
    background-color: var(--header-bg-color);
    padding: 20px 0;
    margin-top: 20px;
    line-height: 1.85rem;
    padding-bottom: 200px;

}

.imgContainer {
    width: 100%;
    padding: 12%;
    background-color: #E2E2E2;
    margin-top: 20px;
}

.imgContainer img {
    width: 100%;
}

.mobileDarkBg .mbileIntro {
    color: white !important;
    margin-left: 4%;
    margin-top: 20px;
}

.mobileDarkBg .mbileIntro span {
    margin-left: -17px;
}

.mobileContentText {
    color: var(--turquoise);
    font-family: "Noto Sans JP", sans-serif;
    font-size: 0.9rem;
    text-align: justify;
}

.movetoTop {
    margin-top: -180px;
}