.AddCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 250px;
  cursor: pointer;
}

.AddCard:hover {
  background-color: rgb(238, 238, 238);
}

.plusIcon {
  font-size: 10rem !important;
  font-weight: 400;
}

.deleteIcon {
  color: red;
  cursor: pointer;
}

.groupContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.cardItem {
  width: 250px;
  height: auto;
}

.groupName {
  text-decoration: none;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

@media (max-width: 576px) {

  .AddCard {
    width: 150px;
  }

  .cardItem {
    width: 150px;
    height: auto;
  }

  .groupName {
    font-size: 0.8rem;
  }
}

.GroupImg {
  width: 100%;
}

.GroupImg img {
  object-fit: cover;
}