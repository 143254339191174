.header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999999;
    max-width: 100vh;
}

.headerContent {
    background-color: var(--header-bg-color);
    width: 100vw;
    display: flex;
    height: 120px;
    padding-top: 10px;
    align-items: center;
}

@media (max-width: 576px) {
    .headerContent {
        flex-direction: column;
        max-width: 100vw;
        height: 135px;
        padding-top: 0;
    }
}

.brand {
    width: 168px;
    padding: 0 15px;
}

@media (max-width: 576px) {
    .brand {
        width: 100%;
        padding: 20px 20px 13px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}



.brand img {
    /* width: 100%; */
    width: 175px;
    height: auto;
}

@media (max-width: 576px) {
    .brand img {
        width: 130px;
    }
}

.menubar {
    width: 55px;
    height: 17px;
    position: relative;
    cursor: pointer;
}

.menubar::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--turquoise);
    position: absolute;
    top: 0;
    transition: all 0.5s ease-in;

}

.menubar::before {
    content: "";
    width: 60%;
    height: 2px;
    background-color: var(--turquoise);
    position: absolute;
    bottom: 0;
    right: 0;
    transition: all 0.5s ease-in;
}

.links {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 160px - 53px);
    border-bottom: 1px solid white;
    margin-bottom: 7px;
    padding-bottom: 10px;
    padding-right: 3px;
}

@media (max-width: 576px) {
    .links {
        border-bottom: none;
        width: calc(100% - 42px);
        border-top: 1px solid white;
        padding: 21px 0 0 0;
        justify-content: space-between;
    }
}

.link {
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 2.24%;
    text-align: center;
}

@media (max-width: 576px) {
    .link {
        margin: 0;
    }
}

.link:last-child {
    margin-right: 0 !important;
}

.link p {
    font-family: "Lato", sans-serif;
    color: white;
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-weight: 400;
    margin-bottom: 0 !important;
    letter-spacing: 0.10rem;
    width: 100%;
}




@media (max-width: 576px) {
    .link p {
        font-size: 1.23rem;
        letter-spacing: 1px;
        line-height: 0.6rem;
        margin-bottom: 0px !important;
        color: white;
    }
}

.link span {
    color: white;
    font-size: 0.75rem !important;
}

.active p {
    color: var(--light-turquoise) !important;
}

@media (max-width: 576px) {
    .link span {
        font-size: 0.65rem !important;
        font-family: "Noto Sans JP", sans-serif;
    }
}

.active span {
    color: var(--light-turquoise) !important;
}

/* Mobile Menu Starts */

.mobileMenu {
    background-color: white;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    z-index: 999999999;
    transition: all 0.5s ease-in;
    overflow-y: scroll;
}

.mobileMenu .headerContent {
    background-color: white !important;
    width: 100vw;
    display: flex;
    height: 130px;
    align-items: center;
}



.mobileMenu .menubar::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--header-bg-color);
    position: absolute;
    top: 0;
    transform: rotate(0deg);
    transition: all 0.7s ease-in;
}

.mobileMenu .menubar::before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--header-bg-color);
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.7s ease-in;

}

.mobileMenu .menubar {
    width: 40px;
    height: 2px;
    position: relative;
    cursor: pointer;
}

.mobileLinks {
    height: calc(100%-130px - 50px);
    margin-top: 23%;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 6.8%;
}


.mobileLinks a {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.5rem;
    margin: 3.7% 0;
    font-weight: 300;
}

.joinUS {
    width: 180px;
    white-space: nowrap;
    border: 1px solid var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    border-radius: 35px;
    margin-top: 6.5% !important;
}

.joinUS p {
    margin-bottom: 0 !important;
    font-weight: 500;
}

.dot {
    color: var(--light-turquoise);
    font-size: 0.8rem;
    margin-right: 10px;
}

.social {
    width: 100%;
    display: flex;
    margin-top: 5% !important;
    padding-left: 5px !important;
}

.social img {
    width: 40px;
}

.mobileMenuOpening {
    right: 0%
}


.mobileMenuOpening .menubar::after {
    transform: rotate(-45deg) !important;
}

.mobileMenuOpening .menubar::before {
    transform: rotate(45deg) !important;
}


.mobileMenuOpeningHeader .menubar::after {
    transform: rotate(-45deg) !important;
}

.mobileMenuOpeningHeader .menubar::before {
    transform: rotate(45deg) !important;
    width: 100%;
}