.membership {
    margin-top: 120px;
    margin-bottom: 7%;
}

@media (max-width: 576px) {
    .membership {
        margin-top: 135px;
    }

    .membership .container {
        padding-right: calc(calc(var(--bs-gutter-x)* .5) + 5px) !important;
        padding-left: calc(calc(var(--bs-gutter-x)* .5) + 5px) !important;
    }
}


.pageIntro {
    background-image: url(../../../public/img/membership.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    max-width: 100%;
    height: 27vw;
    display: flex;
    justify-content: center;
    align-items: self-start;
    flex-direction: column;
}


.title {
    font-size: 6.5rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    line-height: 6.2rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.7rem;
        line-height: 4rem;
        margin-right: auto;
        margin-left: auto;
        font-weight: 300;
        display: block;
    }
}

.subtitle {
    font-size: 1.6rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: 300;
}

@media (max-width: 576px) {
    .subtitle {
        font-size: 1.35rem;
        letter-spacing: 0.1rem;
        font-family: 'Bebas Neue Pro', sans-serif;
        font-weight: 300;
        margin-right: auto;
        margin-left: auto;
        display: block;
    }
}


@media (max-width: 576px) {

    .pageIntro {
        width: 100vw;
        height: 90.5vw;
        background-image: url(../../../public/img/membership-sm.webp);

    }
}

.introTitle {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 2rem;
    margin-top: 7%;
    margin-left: -1.6%;
    text-align: center;
    font-weight: 500;
}

@media (max-width: 576px) {
    .introTitle {
        text-align: start;
        font-size: 1.2rem;
        margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 20px;
    }
}

.introTitleD {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-color);
    font-size: 1rem;
    text-align: center;
    line-height: 1.8rem;
    font-weight: 500;
    margin-bottom: 5%;
}

@media (max-width: 576px) {
    .introTitleD {
        text-align: start;
        font-size: 0.9rem;
    }
}

.cardTitle {
    display: flex;
    align-items: baseline;
    justify-content: start;
    border-bottom: 2px solid var(--light-turquoise);
    margin-bottom: 15px;
}

.cardTitle p:nth-child(1) {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.6rem;
    color: var(--text-color);
    font-weight: 500;
    margin-bottom: 5px;
    font-weight: 500;
    margin-right: 15px;
}

.cardTitle p:nth-child(2) {
    font-family: "Lato", sans-serif;
    font-size: 1.1rem;
    color: var(--light-turquoise);
    font-weight: 500;
    margin-bottom: 5px;
    font-weight: 500;
}

@media (max-width: 576px) {
    .cardTitle p:nth-child(1) {
        font-size: 1.2rem;
        color: var(--dark-gray);
        white-space: nowrap;
    }

    .cardTitle p:nth-child(2) {
        font-size: 1.1rem;
        white-space: nowrap;
    }
}

@media (max-width: 410px) {
    .cardTitle p:nth-child(2) {
        font-size: 0.95rem;
        white-space: nowrap;
    }
}

.cardDetails {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 0.97rem;
    font-weight: 500;
    margin-bottom: 5px;
    font-weight: 500;
    color: var(--text-color);
    line-height: 1.85rem;
    text-align: justify;
}

@media (max-width: 576px) {
    .cardDetails {
        font-size: 0.9rem;
        margin-bottom: 40px;
    }
}


.cardDetails table td {
    vertical-align: top;
    padding: 5px 0;

}

.cardDetails table td:nth-child(1) {
    padding-right: 20px;
    font-size: 1.1rem;
}

.cardDetails span {
    font-size: 1.1rem
}

@media (max-width: 576px) {
    .cardDetails table td:nth-child(1) {
        padding-right: 20px;
        font-size: 1rem;
    }

    .cardDetails span {
        font-size: 1rem;
        margin-bottom: 40px;
    }
}

@media (max-width: 400px) {
    .cardDetails table td:nth-child(1) {
        padding-right: 10px;
        font-size: 0.9rem;
    }

    .cardDetails span {
        font-size: 0.9rem;
        margin-bottom: 40px;

    }
}

.spaceRemover {
    font-size: 0.97rem;
    margin-left: -2%;
}


.links {
    width: 100%;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

@media (max-width: 576px) {
    .links {
        flex-direction: column;
        gap: 20px;
    }
}



.button {
    border: 2px solid var(--light-turquoise);
    background-color: white;
    color: #333;
    padding: 13px 40px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-decoration: none;
    text-align: center;
}



.button:nth-child(1) {
    padding: 13px 30px;
}

.button:hover {
    background-color: var(--light-turquoise);
    color: white;
}

@media (max-width: 576px) {
    .button {
        width: 280px;
        border-radius: 10px;
        padding: 15px 0;
        padding: 7px 40px !important;
        font-size: 1rem;

    }

    .button {
        margin-bottom: 15px;
    }
}

.spaceRemover {
    margin-left: -3%;
}