.member {
    margin-top: 120px;
    padding-top: 70px;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 10%;
}

@media (max-width: 576px) {
    .member {
        margin-top: 135px;
        padding-top: 50px;
        padding-left: 6%;
        padding-right: 6%;
        margin-bottom: 20%;
    }
}

.title {
    color: var(--title-color);
    font-family: 'Bebas Neue Pro', sans-serif;
    text-align: center;
    font-size: 3.6rem;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

@media (max-width: 576px) {
    .title {
        text-align: start;
        letter-spacing: 1px;
        line-height: 3.6rem;
        margin-top: 5px;
    }
}


.subtitle {
    color: var(--light-turquoise);
    font-family: "Noto Sans JP", sans-serif;
    text-align: center;
    font-size: 1.2rem;
    letter-spacing: 1px;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.3rem;
}

@media (max-width: 576px) {
    .subtitle {
        text-align: start;
        letter-spacing: 1px;
        letter-spacing: -0.5px;

    }
}

.greetings {
    font-family: "Noto Sans JP", sans-serif;
    letter-spacing: 2.2px;
    margin-left: 7.8%;
    margin-top: 10%;
    color: var(--light-turquoise);


}

@media (max-width: 576px) {
    .greetings {
        margin-left: -3%;
        margin-top: 8%;
        color: var(--light-turquoise);
        font-weight: 400;
    }
}

.description {
    font-family: "Noto Sans JP", sans-serif;
    letter-spacing: 2.2px;
    margin-left: 7.8%;
    margin-top: 4.5%;
    font-size: 1.15rem;
    line-height: 2.1rem;
    color: var(--text-color);
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}

@media (max-width: 576px) {
    .description {
        margin-left: -3%;
        margin-top: 8%;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.3rem;

    }
}

.details {
    padding: 0 1.5%;
    margin-top: 5%;
}

.item {
    margin-top: 5.5%;
    border-bottom: 2px solid var(--light-turquoise);
}

.itemBox {
    margin-top: 17%;

}

.boxValue {
    border: 2px solid var(--light-turquoise);
    width: 100%;
    height: auto;
    min-height: 300px;
    padding: 10px;
}

.lable,
.value {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--text-label-color);
    font-size: 1.2rem;
    line-height: 2.1rem;
    letter-spacing: 1px;
    margin-bottom: 2px;
}

.button {
    border: 2px solid var(--light-turquoise);
    background-color: white;
    margin: 0 auto;
    color: #333;
    padding: 13px 0px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 200px;
    text-decoration: none;
    text-align: center;
    margin-top: 8%;
}


@media (max-width: 576px) {
    .button {
        width: 230px;
        border-radius: 3px;
        padding: 15px 0;
    }
}

.button:hover {
    background-color: var(--light-turquoise);
    color: white;
}