ul {
    list-style-type: none;
    padding-left: 0; 
    margin: 0; 
  }

  li {
    margin-bottom: 0.5rem;
  }

.privacy {
  margin-top: 120px;
  margin-bottom: 7%;
}

.pageIntro {
  background-image: url(../../../public/img/privacy.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  max-width: 100%;
  height: 27vw;
  display: flex;
  justify-content: center;
  align-items: self-start;
  flex-direction: column;
}


.title {
  font-size: 6.5rem;
  color: white;
  font-family: 'Bebas Neue Pro', sans-serif;
  line-height: 6.2rem;
  font-weight: 500;
}

.subtitle {
  font-size: 2rem;
  color: white;
  font-family: 'Bebas Neue Pro', sans-serif;
  font-weight: 400;
}

.introTitle {
  font-family: "Noto Sans JP", sans-serif;
  color: var(--text-color);
  font-size: 1.1rem;
  margin-top: 5%;
  text-align: center;
  font-weight: 500;
  line-height: 2.2rem;
  border-bottom: 1px solid var(--turquoise);
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 50px;
  padding-bottom: 26px;
}

h5 {
  position: relative;
  padding-left: 14px;
}

h5::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 20px;
  background: var(--dark-gray);
  top: 3px;
  left: 0px;
}

.privacyHeading {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.2rem;
  color: var(--dark-gray);
}

.privacySubtitle {
  font-family: "Nato Sans JP", sans-serif;
  font-weight: 600;
  margin-top: 25px;
  font-size: 1rem;
  color: var(--title-color);
}

.listInfo {
  font-family: "Nato Sans JP", sans-serif;
  font-weight: 600;
  color: var(--title-color);
}

@media (max-width: 410px) {
  .privacyHeading {
    font-size: 64rem;
  }
}

@media (max-width: 576px) {

  .pageIntro {
      width: 100vw;
      height: 90.5vw;
      background-image: url(../../../public/img/privacy.webp);

  }

  .title {
    font-size: 3.7rem;
    line-height: 4rem;
    margin-right: auto;
    margin-left: auto;
    font-weight: 300;
    display: block;
}

  .subtitle {
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: 700;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }

  .introTitle {
    text-align: justify;
    font-size: 0.88rem;
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 30px;
    margin-top: 14%;
}

.privacyHeading {
  font-size: 0.84rem;

}

h5::before {
  content: "";
    position: absolute;
    width: 5px;
    height: 16px;
    background: var(--dark-gray);
    top: 1px;
    left: 3px;
}

.privacySubtitle { 
  font-size: 0.72rem;
  margin-top: 14px;
}

.listInfo {
  font-size: .72rem;
}


}





  