.event {
    display: block;
    margin-bottom: 60px;
    margin-top: 120px;

}

@media (max-width: 576px) {
    .event {
        margin-top: 135px;
    }
}

.pageIntro {
    background-image: url(../../../public/img/event.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    max-width: 100%;
    height: 47.75vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 576px) {

    .pageIntro {
        width: 100vw;
        height: 90.5vw;
        background-image: url(../../../public/img/event-sm.webp);

    }
}

.title {
    font-size: 6.5rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    line-height: 6.2rem;
    font-weight: 500;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.7rem;
        line-height: 4rem;
        margin-right: auto;
        margin-left: 6%;
        font-weight: 300;
    }
}

.subtitle {
    font-size: 1.6rem;
    color: white;
    font-family: 'Bebas Neue Pro', sans-serif;
    font-weight: 300;
}

@media (max-width: 576px) {
    .subtitle {
        font-size: 1rem;
        color: white;
        font-family: 'Bebas Neue Pro', sans-serif;
        font-weight: 300;
        margin-right: auto;
        margin-left: 6%;
    }
}

.description {
    background-color: var(--light-turquoise);
    padding: 6% 0;
}

/* .description p {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.2rem;
    margin: 0 !important;
    letter-spacing: 0.18rem;
    line-height: 2.5rem;
} */

@media (max-width: 576px) {

    .description {
        padding-bottom: 150px;
    }

    /* .description p {
        font-family: "Noto Sans JP", sans-serif;
        font-size: 0.85rem;
        margin: 0 !important;
        letter-spacing: 0.01rem;
        line-height: 1.5rem;
    } */
}

.mobileList {
    margin-top: -150px;
}


.eventTitle {
    color: var(--text-color);
    font-size: 1.2rem;
    font-family: "Noto Sans JP", sans-serif;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 0 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 576px) {
    .eventTitle {
        font-size: 0.9rem;

    }
}


.eventDate {
    color: var(--text-color);
    font-size: 0.85rem;
    font-family: "Lato", sans-serif;
    margin-bottom: 0;
    margin-top: 0px;
    letter-spacing: 1px;
    font-weight: 600;
}

@media (max-width: 576px) {
    .eventDate {
        font-size: 0.8rem;
        font-weight: 500;
    }

}

.link {
    text-decoration: none;
    display: block;
    transition: all 0.5s ease-in;
}

.link:hover {
    transform: scale(1.05);
}


.eventDetails {
    margin-top: 120px;

}

@media (max-width: 576px) {
    .eventDetails {
        margin-top: 135px;
    }
}

.eventDetails .intro {
    background-color: var(--light-turquoise);
    padding: 6% 10%;

}

@media (max-width: 576px) {
    .eventDetails .intro {
        padding: 70px 20px 70px 20px;

    }
}


.eventDetails .intro .img {
    object-fit: cover;
    width: 100%;
}

.eventDetails .intro .title {
    font-size: 2rem;
    color: black;
    font-family: "Noto Sans JP", sans-serif;
    line-height: 2.2rem;
    margin-left: 10%;
}

.eventDetails .intro .date {
    font-size: 1.1rem;
    color: black;
    font-family: "Lato", sans-serif;
    line-height: 1.1rem;
    margin-bottom: 10px !important;
    margin-left: 10%;
}

.eventDetails .intro .description {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1rem;
    line-height: 1.8rem;
    letter-spacing: 0.05rem;
    font-weight: 500;
    color: var(--title-color);
    text-align: justify;
    /* display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; */
    margin-right: 10%;
    margin-left: 10%;
    padding-bottom: 0;

}

@media (max-width: 576px) {
    .eventDetails .intro .title {
        margin-left: 0;
        margin-top: 15px;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.6rem;
        margin-bottom: 10px !important;
    }

    .eventDetails .intro .date {
        margin-left: 0;
        font-size: 1rem;
        margin-bottom: 0 !important;
        line-height: 1.1rem;
    }

    .eventDetails .intro .description {
        margin-left: 0;
        margin-top: -5px;
        font-size: 0.9rem;
        margin-right: 0;
        line-height: 1.4rem;
    }
}

.goBackLink {
    border: 2px solid var(--light-turquoise);
    background-color: white;
    color: #333;
    padding: 13px 40px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-decoration: none;
    text-align: center;
}


.goBackLink:hover {
    background-color: var(--light-turquoise) !important;
    color: white;
}


@media (max-width: 576px) {
    .goBackLink {
        width: 280px;
        border-radius: 10px;
        padding: 15px 0;
        padding: 7px 40px !important;
        font-size: 1rem;
    }
}



.toggleDescription {
    display: inline;
    cursor: pointer;
}

.toggleDescription:hover {
    opacity: .7;
}