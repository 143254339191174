.navWizard {
  width: fit-content !important;
  padding: 0;
}

.navWizard>li {
  float: left;
}

.navWizard>li>a {
  position: relative;
  background-color: var(--cmn-ash-lite);
  padding: 10.5px 15px;
  font-weight: 500;
  color: var(--cmn-text-color-ash);
  text-decoration: none;
}

.navWizard>li>a .badge {
  margin-left: 3px;
  color: var(--cmn-ash-lite);
  background-color: var(--cmn-yollow);
}

.navWizard>li:not(:first-child)>a {
  padding-left: 34px;
}

.navWizard>li:not(:first-child)>a:before {
  width: 0px;
  height: 0px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid white;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}

.navWizard>li:not(:last-child)>a {
  margin-right: 6px;
}

.navWizard>li:not(:last-child)>a:after {
  width: 0px;
  height: 0px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid var(--cmn-ash-lite);
  position: absolute;
  content: "";
  top: 0;
  right: -20px;
  z-index: 2;
}

.navWizard>li:first-child>a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.navWizard>li:last-child>a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.navWizard>li.done:hover>a,
.navWizard>li:hover>a {
  background-color: #d5d5d5;
}

.navWizard>li.done:hover>a:before,
.navWizard>li:hover>a:before {
  border-right-color: #d5d5d5;
}

.navWizard>li.done:hover>a:after,
.navWizard>li:hover>a:after {
  border-left-color: #d5d5d5;
}

.navWizard>li.done>a {
  background-color: #e2e2e2;
}

.navWizard>li.done>a:before {
  border-right-color: #e2e2e2;
}

.navWizard>li.done>a:after {
  border-left-color: #e2e2e2;
}

.navWizard>li.active>a,
.navWizard>li.active>a:hover,
.navWizard>li.active>a:focus {
  font-weight: 500;
  color: var(--cmn-text-color-dark);
  background-color: var(--cmn-yollow);
}

.navWizard>li.active>a:after {
  border-left-color: var(--cmn-yollow);
}

.navWizard>li.active>a .badge {
  color: var(--cmn-yollow);
  background-color: #ffffff;
}

.navWizard>li.disabled>a {
  color: #777777;
}

.navWizard>li.disabled>a:hover,
.navWizard>li.disabled>a:focus {
  color: #777777;
  text-decoration: none;
  background-color: var(--cmn-ash-lite);
  cursor: default;
}

.navWizard>li.disabled>a:before {
  border-right-color: var(--cmn-ash-lite);
}

.navWizard>li.disabled>a:after {
  border-left-color: var(--cmn-ash-lite);
}

.navWizard.nav-justified>li {
  float: none;
}

.navWizard.nav-justified>li>a {
  padding: 10px 15px;
}

@media (max-width: 768px) {
  .navWizard.nav-justified>li>a {
    border-radius: 4px;
    margin-right: 0;
  }

  .navWizard.nav-justified>li>a:before,
  .navWizard.nav-justified>li>a:after {
    border: none !important;
  }
}

@media screen and (max-width: 576px) {
  .navWizard>li>a {
    padding: 9px 0px;
  }

  .navWizard>li:not(:first-child)>a:before {
    border-top: 19px inset transparent;
    border-bottom: 19px inset transparent;
  }

  .navWizard>li:not(:last-child)>a {
    margin-right: 5px;
  }

  .navWizard>li:not(:last-child)>a:after {
    border-top: 19px inset transparent;
    border-bottom: 19px inset transparent;
  }

  .navWizard>li:not(:first-child)>a {
    padding-left: 25px;
  }

  .navWizard>li:first-child>a {
    padding-left: 10px;
  }

  .navWizard>li:last-child>a {
    padding-right: 10px;
  }
}