.contactus {
    margin-top: 150px;
    margin-bottom: 10%;
}

form {
    width: 100%;
    /* max-width: 788px; */
    /* 850px */
    max-width: 850px;
}

@media only screen and (max-width: 1440px) {
    .contactus {
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media (max-width: 576px) {
    .contactus {
        padding: 40px 2px 80px 2px;
    }
}

.title {
    font-family: 'Bebas Neue Pro', sans-serif;
    font-size: 5rem;
    color: var(--text-color);
    margin-bottom: 0 !important;
    line-height: 6rem;
}

.subtitle {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--light-turquoise);
    font-size: 1.4rem;
}

@media (max-width: 576px) {
    .title {
        font-size: 3.5rem;
        line-height: 3.5rem;
        text-align: end;
    }

    .subtitle {
        font-size: 1rem;
        margin-left: 6px;
        text-align: end;

    }
}

.introTitle {
    font-family: "Noto Sans JP", sans-serif;
    color: var(--dark-gray);
    font-size: 1rem;
    margin-top: 5%;
    text-align: center;
    font-weight: 500;
    line-height: 2.2rem;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 40px;
    padding-bottom: 4px;
}


.customInput::placeholder {
    color: var(--dark-gray);
    opacity: 0.5;
}

.customInput {
    outline: none !important;
    box-shadow: none !important;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    padding-left: 60px;
    /* color: #9EDBD6 !important; */

}

.textinput {
    border-radius: 0;
    border-bottom: 3px solid #9EDBD6;
    font-size: 0.8rem;
    font-family: "Noto Sans JP", sans-serif;
    margin-left: 6%;
    margin-right: 6%;
    margin-bottom: 6%;

}

.agrement {
    border-radius: 0;
    border-bottom: 3px solid #9EDBD6;
    font-size: 0.8rem;
    font-family: "Noto Sans JP", sans-serif;
    margin-left: 6%;
    margin-right: 6%;
    padding-bottom: 6px;
    margin-bottom: 42px;
}

.customTextarea {
    border: none;
    outline: none;
    border-radius: 0;
    color: var(--title-color);
    height: 100px;
    resize: none;
    overflow: hidden;
}

.customTextarea::placeholder {
    color: var(--dark-gray);
    opacity: 0.5;
}

.label {
    color: var(--dark-gray);
    letter-spacing: -1px;
    margin-left: -12px;
    font-family: "Noto Sans JP", sans-serif;
}

.textinput:focus,
.customTextarea:focus {
    box-shadow: none
}

.send {
    border: 2px solid var(--light-turquoise) !important;
    background-color: white !important;
    color: #333 !important;
    padding: 13px 0px;
    border-radius: 12px;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: "Noto Sans JP", sans-serif;
    transition: all 0.3s ease, color 0.3s ease !important;
    width: 270px;
    text-decoration: none;
    text-align: center;
}

@media (max-width: 576px) {
    .send {
        width: 230px;
        border-radius: 3px;
        padding: 15px 0;
    }
}

.send:hover {
    border: 2px solid var(--light-turquoise);
    background-color: var(--light-turquoise) !important;
    color: white;
}

.cancel {
    width: 270px;
    background-color: #F58C00;
    border-color: #F58C00;
    color: var(--text-color);
    font-size: 1.1rem;
    color: white;
    padding: 13px 0px;
    border-radius: 12px;
    border: 2px solid #F58C00;
    transition: all 0.3s ease, color 0.3s ease !important;

}

.cancel:hover {
    border: 2px solid var(--light-turquoise);
    background-color: var(--light-turquoise) !important;
    color: white;
}

.CustomBtnContainer {
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 107px;

}

.customTextarea {
    outline: 'none';
    box-shadow: none;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    max-width: 760px;
    padding: 0px;
    box-shadow: none;

}




.trems {
    font-size: 1rem;
    color: #000000;
    margin-left: -12px;
}

@media (max-width: 576px) {

    .textinput,
    .agrement {
        margin-left: 16px;
        margin-right: 26px;
    }

    .textinput,
    .customTextarea {
        border-bottom: 2px solid #9EDBD6;

    }

    .introTitle {
        text-align: justify;
        font-size: 1rem;
        margin-left: 16px;
        margin-right: 26px;
        padding-bottom: 0px;
    }

    .customInput {
        text-align: left !important;
        margin-left: -12px;
        font-size: 1.1rem;
        padding-left: 0px !important;
    }

    .CustomBtnContainer {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        margin-bottom: 44px;

    }

    .send,
    .cancel {
        width: 250px;
        border-radius: 3px;
        padding: 8px 0;
        font-size: 1rem;

    }

    .cancel {
        margin-top: -14px;
    }



    .customTextarea {
        height: 120px;
        width: 100%;
        border-bottom: 0 !important;
    }

    .trems {
        font-size: .88rem;
        margin-left: 1px;
    }

    .agrement {
        border-bottom: 2px solid #9EDBD6;
    }

    .error {
        padding-left: 0px !important;
        margin-left: -12px;
    }

    .privacyerror {
        padding-left: 12px;
    }
}

.error {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    padding-left: 60px;
    border-color: none;
    outline: 'none';
    box-shadow: none;

}

.textareaError {
    color: red;
}

.privacyerror {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    margin-left: 20px;
}

.textinput .form-control.is-invalid {
    /* border-color: red;  */
    border-color: none;
    outline: 'none';
    box-shadow: none;

}

.customTextarea.is-invalid {
    border-color: none;
    outline: 'none';
    box-shadow: none;
}




.checkboxContainer {
    display: flex;
    align-items: center;
}

.customCheckbox {
    margin-right: 18px;
}

.customCheckbox input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 24px;
    /* Set custom size */
    height: 24px;
    border: 2px solid #17a2b8;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    margin-left: -11px;
}

.trems {
    display: inline;
    color: #000;
}

.invalidCheckbox {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    -webkit-box-shadow: 0px 0px 2px 2px rgba(255, 0, 0, 1);
    -moz-box-shadow: 0px 0px 2px 2px rgba(255, 0, 0, 1);
    box-shadow: 0px 0px 2px 2px rgba(255, 0, 0, 1);
}