.socialButtons {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  
}

.socialButtons .icon {
  color: var(--gray-brown);
  margin-right: 14px;
  font-size: 2rem;
  cursor: pointer;
  height: 2rem;
}


.socialButtons .icon:hover {
  color: black;
}