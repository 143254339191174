.sidebar {
    display: flex;
    flex-direction: column;
}

@media (max-width: 576px) {
    .sidebar {
        display: none;
    }
}

.showMobileSidebar {
    display: flex;
    width: 90%;
    margin-left: 5%;
}

.linkTitle {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.45rem;
    padding: 10px;
    padding-bottom: 15px;
    border-bottom: 2px solid var(--light-turquoise);
}

.sidebarLink {
    margin-left: 5%;
    width: 92%;
    text-decoration: none;
}

.sidebarLink {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.25rem;
    padding: 14px 10px;
    padding-bottom: 14px;
    color: var(--text-color);
    border-bottom: 1px solid var(--light-turquoise);
    margin-bottom: -2px;
}

.sidebarLink:hover {
    color: var(--light-turquoise) !important;
}

.active {
    background-color: var(--light-turquoise);
    width: 100%;
    margin-left: 0;
    padding-left: calc(12px + 5%);
    color: white;
}

.active:hover {
    color: white !important;
}

@media (max-width: 576px) {
    .linkTitle {
        font-size: 1.1rem;
        padding: 6px;
        padding-bottom: 8px;
    }

    .sidebarLink {
        font-size: 1rem;
        padding: 12px 7px;
        padding-bottom: 14px;
        margin-left: 2%;
    }

    .active {
        background-color: var(--light-turquoise);
        width: 100%;
        margin-left: 0;
        padding-left: calc(12px + 2%);
        color: white;
    }
}