.top {
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    background-color: var(--sidebar-bg-color);
}

@media (max-width: 576px) {
    .top {
        margin-top: 130px;
        height: calc(100vh - 130px);
    }
}

.sidebar {
    height: 100%;
    background-color: var(--sidebar-bg-color);
    width: 22.5%;
    padding: 2.4% 0 3.7% 2.6%;
    display: flex;
    flex-direction: column;
    z-index: 999;
}

@media (max-width: 1440px) {
    .sidebar {
        width: 27.5%;
    }
}

.brand {
    width: 50%;
    text-decoration: none;
}

.brand img {
    /* width: 100%; */
    width: 175px;
    margin-left: -18%;
    height: auto;
}

.menu {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.link {
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
    margin: 0px 0;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1440px) {

    .menu {
        height: -webkit-fill-available;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .link {
        margin: 20px 0;
    }
}


@media (max-width: 1369px) {
    .link {
        margin: 25px 0;
    }
}

.link:first-child {
    margin-top: 50px;
}




.link p {
    font-family: "Lato", sans-serif;
    color: var(--light-turquoise);
    font-size: 1.35rem;
    line-height: 1.2rem;
    font-weight: 400;
    margin-bottom: 0 !important;
    letter-spacing: 0.18rem;
    width: 100%;
}

.link span {
    color: white;
    font-size: 1rem !important;
}


@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {

    .menu {
        justify-content: space-between;
    }

    .link {
        margin: 28px 0;
    }

    .link p {
        font-size: 1.6rem;
        line-height: 1.5rem;
    }

    .link span {
        font-size: 1.1rem !important;
    }

    @media (max-width: 1440px) {
        .menu {
            justify-content: start;
        }

        .link {
            margin: 22px 0;
        }

        .link p {
            font-size: 1.3rem;
            line-height: 1.2rem;
        }

        .link span {
            font-size: 1rem !important;
        }
    }

}

.textDiv {
    width: calc(100% - 23.2%);
    display: flex;
    padding-right: 3%;
    height: 100%;
    justify-content: end;
    align-items: center;
    z-index: 999;
}

@media (max-width: 576px) {
    .textDiv {
        width: 100%;
        justify-content: start;
        align-items: end;
    }
}

.textDiv p {
    color: white;
    font-weight: 300;
    font-size: 6rem;
    line-height: 5.8rem;
    font-family: 'Bebas Neue Pro', sans-serif;
    margin-top: 3.5%;
    letter-spacing: 0.5px;
}

@media (max-width: 576px) {
    .textDiv p {
        font-weight: 300;
        font-size: 3.5rem;
        margin-top: unset;
        margin-bottom: 26%;
        margin-left: 3.7%;
        line-height: 3.65rem;
        letter-spacing: 1.5px;
    }
}

.tags {
    position: absolute;
    bottom: 1.5%;
    right: 3.2%;
    width: 260px;
    z-index: 999;
}

.tags span {
    color: white;
    font-size: 1.2rem;
    position: relative;
}

.tags span::after {
    content: " / ";
}

.tags span:last-child::after {
    content: "";
}

.links {
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    position: absolute;
    right: 4.5%;
    top: 3.5%;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    z-index: 9999;
}

.scroll {
    width: 179px;
    height: 178px;
    border-radius: 50%;
    position: absolute;
    border: 1px solid #fff;
    bottom: -92px;
    left: 3%;
    right: 0%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    z-index: 999;
}

@media (max-width: 576px) {
    .scroll {
        display: none;
        width: 180px;
        height: 180px;
        bottom: -90px;
        left: 0%;
        right: 0%;
    }
}

.scroll p {
    font-family: "Lato", sans-serif;
    text-align: center;
    color: #fff;
    margin-top: 30px;
    font-weight: 300;
    margin-bottom: 0;
    font-size: 1.2rem;
}

.scrollIcon {
    color: #fff;
    margin: 0 auto;
}

.topSlide {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.slide {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

@media (max-width: 576px) {
    .slide {
        height: calc(100vh - 130px);
    }
}

.slide img {
    height: 100%;
    object-fit: cover;
}