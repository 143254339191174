.answer {
    width: 100%;
    background-color: #dcefec;
    padding: 50px 50px 40px 110px;
    position: relative;
}

.answer::before {
    width: 56px;
    height: 56px;
    content: "";
    position: absolute;
    background-image: url(../../../../public/img/aIcon.webp);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    left: 2.5%;
    top: 30px;
}

@media (max-width: 576px) {

    .answer {
        padding: 65px 30px 20px 30px;

    }

    .answer::before {
        width: 40px;
        height: 40px;
        left: 1.5%;
        top: 20px;
    }
}

.answer p {
    color: var(--text-color);
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.1rem;
    line-height: 2rem;
    text-align: justify;
}

@media (max-width: 576px) {
    .answer p {
        font-size: 0.8rem;
        line-height: 1.4rem;
    }
}

.answer a {
    color: var(--text-color);
    font-family: "Noto Sans JP", sans-serif;
    font-size: 1.1rem;
    line-height: 2rem;
    text-align: justify;
    text-decoration: underline;
    color: #4BC5B9;
}

@media (max-width: 576px) {
    .answer a {
        font-size: 0.8rem;
        line-height: 1.4rem;
    }
}



.tableContainer {
    font-family: "Noto Sans JP", sans-serif;
    width: 55%;
    margin: 8% 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.tableContainer svg {
    height: -webkit-min-content;
    width: -webkit-fill-available;
}



@media (max-width: 576px) {
    .tableContainer {
        width: 100%;
        margin: 8% 0%;
    }
}

.customTable {
    border-collapse: collapse;
    width: 80%;
    background-color: transparent;
}

.customTable th,
.customTable td {
    text-align: center;
    padding: 10px;
    font-size: 16px;
}

.customTable th {
    font-weight: 700;
}

.customTable td {
    font-weight: 400;
}

.customTable tbody tr:nth-child(even) {}

@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {


    @media (max-width: 576px) {
        .tableContainer {
            width: 86% !important;
            margin: 4% 7% !important;
        }

        .tableContainer svg {
            height: 250px !important;
        }
    }

}